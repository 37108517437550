import * as React from 'react';
import { Form, Grid, Header, Icon, List, Message, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from '../../components/styles.module.scss';

const Contact: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Row>
        <Grid.Column width={5}>
          <Header as="h4" content={t('footer.title')} />
          <List link>
            <List.Item>
              <List.Icon name="user" />
              <List.Content className={styles.listContent}>Ivan Dereviahin</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="marker" />
              <List.Content className={styles.listContent}>{t('footer.address-real')}</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="mail" />
              <List.Content className={styles.listContent}>
                <a href="mailto:ivan.dereviahin@gmail.com">ivan.dereviahin@gmail.com</a>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="mobile" />
              <List.Content className={styles.listContent}>
                <a href="tel:+380665120346">+38(066)512-03-46</a>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="copyright" />
              <List.Content className={styles.listContent}>
                This site is owned and operated by <strong>Ivan Dereviahin.</strong>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <Header as="h2" color="teal" textAlign="center">
            {t('contact.title')}
          </Header>
          <Form size="large">
            <Segment stacked>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  iconPosition="left"
                  icon="user"
                  label={t('contact.name')}
                  placeholder={t('contact.name')}
                />
                <Form.Input
                  required
                  icon="mail"
                  iconPosition="left"
                  label={t('contact.email')}
                  placeholder={t('contact.email-placeholder')}
                />
              </Form.Group>
              <Form.TextArea required label={t('contact.message')} placeholder={t('contact.message-placeholder')} />
              <Form.Button color="teal" size="large">
                {t('contact.submit')}
              </Form.Button>
            </Segment>
          </Form>
          <Message>
            {t('contact.email-us')}
            {'  '}
            <a href="mailto:ivan.dereviahin@gmail.com">
              <Icon size="big" name="mail" color="teal" />
            </a>
          </Message>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Contact;
