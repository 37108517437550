import * as React from 'react';
import { Segment, Container, Header, Divider, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const About: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Segment vertical className={styles.root}>
      <Container text className={styles.container}>
        <Header as="h3">{t('about-us.title')}</Header>
        <p>{t('about-us.description')}</p>
        <Divider section className={styles.divider} />
        <Header as="h3">{t('about-us.values-title')}</Header>
        <p>{t('about-us.values-description')}</p>
        <List className={styles.list}>
          <List.Item>
            <List.Icon name="check" />
            <List.Content>
              <List.Header className={styles.listHeader}>{t('about-us.value-1')}</List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="check" />
            <List.Content>
              <List.Header className={styles.listHeader}>{t('about-us.value-2')}</List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="check" />
            <List.Content>
              <List.Header className={styles.listHeader}>{t('about-us.value-3')}</List.Header>
            </List.Content>
          </List.Item>
        </List>
      </Container>
    </Segment>
  );
};

export default About;
