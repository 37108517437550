import * as React from 'react';
import { Container, Image, Header } from 'semantic-ui-react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import headerImg from '../assets/header.jpg';

interface Props {
  mobile?: boolean | undefined;
  path?: string | null;
}

const HomepageHeading: React.FC<Props> = ({ mobile, path }) => {
  const ref = React.useRef<any>();
  const { t } = useTranslation();

  if (path !== '/') {
    return null;
  }

  return (
    <Parallax pages={1.5} ref={ref}>
      <ParallaxLayer offset={0} speed={0}>
        <Container>
          <Image src={headerImg} fluid />
        </Container>
      </ParallaxLayer>
      <ParallaxLayer offset={0.2} speed={-0.7}>
        <Container text className={styles.headerContainer}>
          <Header
            as="h2"
            style={{
              marginTop: mobile ? '0.5em' : '1.5em',
            }}
            content={t('home.title')}
          />
          <p>{t('home.title-description')}</p>
        </Container>
      </ParallaxLayer>
    </Parallax>
  );
};

export default HomepageHeading;
