import * as React from 'react';
import { Segment, Image, Container } from 'semantic-ui-react';
import styles from './styles.module.scss';

const Brands: React.FC = () => {
  return (
    <Segment className={styles.root} vertical textAlign="center">
      <Container>
        <Image.Group size="small" className={styles.imageGroup}>
          <Image src="/images/chiaogoo.jpg" />
          <Image src="/images/Mudtools.png" />
          <Image src="/images/Logo-PF-LawnFawn.png" />
          <Image src="/images/averyelle.jpeg" />
          <Image src="/images/suespargo.jpg" />
        </Image.Group>
        <Image.Group size="small" className={styles.imageGroup}>
          <Image src="/images/1956.jpg" />
          <Image src="/images/6420F.jpg" />
          <Image src="/images/5705.jpg" />
          <Image src="/images/7705.jpg" />
        </Image.Group>
      </Container>
    </Segment>
  );
};

export default Brands;
