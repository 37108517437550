import * as React from 'react';
import { Container, Grid, Header, List, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Segment attached="bottom" className={styles.footer}>
      <Container>
        <Grid divided stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header as="h4" content={t('footer.title')} />
              <List link>
                <List.Item>
                  <List.Icon name="user" />
                  <List.Content className={styles.listContent}>Ivan Dereviahin</List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="marker" />
                  <List.Content className={styles.listContent}>{t('footer.address-real')}</List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="mail" />
                  <List.Content className={styles.listContent}>
                    <a href="mailto:ivan.dereviahin@gmail.com">ivan.dereviahin@gmail.com</a>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="mobile" />
                  <List.Content className={styles.listContent}>
                    <a href="tel:+380665120346">+38(066)512-03-46</a>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="copyright" />
                  <List.Content className={styles.listContent}>
                    This site is owned and operated by Ivan Dereviahin.
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default Footer;
