import * as React from 'react';
import { Container, Menu, Responsive, Segment, Visibility, MenuItemProps, Dropdown, Image } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HomePageHeading from './HomePageHeading';
import Footer from './Footer';
import styles from './styles.module.scss';
import logo from '../assets/logo.png';

interface Props {
  children: React.ReactNode;
  routerProps: RouteComponentProps;
}

const DesktopContainer: React.FC<Props> = ({ routerProps, children }) => {
  const [fixed, setFixed] = React.useState(false);
  const [activeItem, setActiveMenu] = React.useState(routerProps.location.pathname);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const hideFixedMenu = () => setFixed(false);
  const showFixedMenu = () => setFixed(true);

  const handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>, { name }: MenuItemProps) => {
    if (name) {
      setActiveMenu(name);
    }
  };

  const getWidth = () => {
    const isSSR = typeof window === 'undefined';
    return isSSR ? Number(Responsive.onlyTablet.minWidth) : window.innerWidth;
  };

  const style = routerProps.location.pathname === '/' ? { minHeight: 600, padding: '1em 0em' } : null;

  return (
    <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
      <Visibility once={false} onBottomPassed={showFixedMenu} onBottomPassedReverse={hideFixedMenu}>
        <Segment textAlign="center" style={style} vertical>
          <Menu
            activeIndex={activeItem}
            fixed={fixed ? 'top' : undefined}
            pointing={!fixed}
            secondary={!fixed}
            size={fixed ? 'mini' : 'large'}
          >
            <Container className={styles.menuContainer}>
              <Menu.Item as={Link} to="/" name="home" onClick={handleItemClick} header>
                <Image size={fixed ? 'mini' : 'tiny'} src={logo} style={{ marginRight: '1.5em' }} />
              </Menu.Item>
              <Menu.Item as={Link} to="/" name="/" active={activeItem === '/'} onClick={handleItemClick}>
                {t('menu.home')}
              </Menu.Item>
              <Menu.Item as={Link} to="/about" name="/about" active={activeItem === '/about'} onClick={handleItemClick}>
                {t('menu.about')}
              </Menu.Item>
              <Menu.Item as={Link} to="/store" name="/store" active={activeItem === '/store'} onClick={handleItemClick}>
                {t('menu.store')}
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/brands"
                name="/brands"
                active={activeItem === '/brands'}
                onClick={handleItemClick}
              >
                {t('menu.brands')}
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/contact"
                name="/contact"
                active={activeItem === '/contact'}
                onClick={handleItemClick}
              >
                {t('menu.contact')}
              </Menu.Item>
              <Menu.Item position="right">
                <Dropdown icon="world">
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <span onClick={() => changeLanguage('eng')}>eng</span>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <span onClick={() => changeLanguage('ukr')}>ukr</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>
            </Container>
          </Menu>
          <HomePageHeading path={routerProps.location.pathname} />
        </Segment>
      </Visibility>
      {children}
      <Footer />
    </Responsive>
  );
};

export default DesktopContainer;
