import * as React from 'react';
import { Container, Dropdown, Icon, Menu, MenuItemProps, Responsive, Sidebar } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import HomePageHeading from './HomePageHeading';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';

interface Props {
  children: React.ReactNode;
  routerProps: RouteComponentProps;
}

const MobileContainer: React.FC<Props> = ({ children, routerProps }) => {
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const [activeItem, setActiveMenu] = React.useState(routerProps.location.pathname);

  const { t, i18n } = useTranslation();

  const handleSidebarHide = () => setSidebarOpened(false);
  const handleToggle = () => setSidebarOpened(true);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>, { name }: MenuItemProps) => {
    if (name) {
      setActiveMenu(name);
      handleSidebarHide();
    }
  };

  return (
    <Responsive as={Sidebar.Pushable} maxWidth={Responsive.onlyMobile.maxWidth}>
      <Sidebar as={Menu} animation="overlay" onHide={handleSidebarHide} vertical visible={sidebarOpened}>
        <Menu.Item as={Link} to="/" name="/" active={activeItem === '/'} onClick={handleItemClick}>
          {t('menu.home')}
        </Menu.Item>
        <Menu.Item as={Link} to="/about" name="/about" active={activeItem === '/about'} onClick={handleItemClick}>
          {t('menu.about')}
        </Menu.Item>
        <Menu.Item as={Link} to="/store" name="/store" active={activeItem === '/store'} onClick={handleItemClick}>
          {t('menu.store')}
        </Menu.Item>
        <Menu.Item as={Link} to="/brands" name="/brands" active={activeItem === '/brands'} onClick={handleItemClick}>
          {t('menu.brands')}
        </Menu.Item>
        <Menu.Item as={Link} to="/contact" name="/contact" active={activeItem === '/contact'} onClick={handleItemClick}>
          {t('menu.contact')}
        </Menu.Item>
        <Menu.Item>
          <Dropdown icon="world">
            <Dropdown.Menu>
              <Dropdown.Item>
                <span onClick={() => changeLanguage('eng')}>eng</span>
              </Dropdown.Item>
              <Dropdown.Item>
                <span onClick={() => changeLanguage('ukr')}>ukr</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Sidebar>
      <Sidebar.Pusher dimmed={sidebarOpened}>
        <Container>
          <Menu pointing secondary size="large">
            <Menu.Item onClick={handleToggle}>
              <Icon name="sidebar" />
            </Menu.Item>
          </Menu>
        </Container>
        <HomePageHeading mobile />
        {children}
        <Footer />
      </Sidebar.Pusher>
    </Responsive>
  );
};

export default MobileContainer;
