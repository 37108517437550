import * as React from 'react';
import { Grid, Segment, Container, Header, Card, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const HomepageLayout: React.FC = () => {
  const { t } = useTranslation();

  const data = {
    column1: [
      { image: '/images/1.jpg', header: t('home.cards.card-1') },
      { image: '/images/2.jpg', header: t('home.cards.card-2') },
      { image: '/images/4.jpg', header: t('home.cards.card-4') },
    ],
    column2: [
      { image: '/images/15.jpg', header: t('home.cards.card-15') },
      { image: '/images/5.jpg', header: t('home.cards.card-5') },
      { image: '/images/6.jpg', header: t('home.cards.card-6') },
    ],
    column3: [
      { image: '/images/7.jpg', header: t('home.cards.card-7') },
      { image: '/images/8.jpg', header: t('home.cards.card-8') },
      { image: '/images/9.jpg', header: t('home.cards.card-9') },
    ],
    column4: [
      { image: '/images/10.jpg', header: t('home.cards.card-10') },
      { image: '/images/11.jpg', header: t('home.cards.card-11') },
      { image: '/images/12.jpg', header: t('home.cards.card-12') },
    ],
    column5: [
      { image: '/images/13.jpg', header: t('home.cards.card-13') },
      { image: '/images/14.jpeg', header: t('home.cards.card-14') },
      { image: '/images/3.jpg', header: t('home.cards.card-3') },
    ],
  };

  return (
    <Segment className={styles.root}>
      <Grid container stackable>
        <Container text textAlign="justified">
          <Header as="h2" content={t('home.title')} />
          <p>{t('home.home-text')}</p>
        </Container>
        <Grid.Row columns={3}>
          {data.column1.map((d, i) => (
            <Grid.Column key={i}>
              <Card centered>
                <Image src={d.image} wrapped ui={false} />
                <Card.Content>
                  <Card.Header>{d.header}</Card.Header>
                </Card.Content>
              </Card>
            </Grid.Column>
          ))}
        </Grid.Row>
        <Grid.Row columns={3}>
          {data.column2.map((d, i) => (
            <Grid.Column key={i}>
              <Card centered>
                <Image src={d.image} wrapped ui={false} />
                <Card.Content>
                  <Card.Header>{d.header}</Card.Header>
                </Card.Content>
              </Card>
            </Grid.Column>
          ))}
        </Grid.Row>
        <Grid.Row columns={3}>
          {data.column3.map((d, i) => (
            <Grid.Column key={i}>
              <Card centered>
                <Image src={d.image} wrapped ui={false} />
                <Card.Content>
                  <Card.Header>{d.header}</Card.Header>
                </Card.Content>
              </Card>
            </Grid.Column>
          ))}
        </Grid.Row>
        <Grid.Row columns={3}>
          {data.column4.map((d, i) => (
            <Grid.Column key={i}>
              <Card centered>
                <Image src={d.image} wrapped ui={false} />
                <Card.Content>
                  <Card.Header>{d.header}</Card.Header>
                </Card.Content>
              </Card>
            </Grid.Column>
          ))}
        </Grid.Row>
        <Grid.Row columns={3}>
          {data.column5.map((d, i) => (
            <Grid.Column key={i}>
              <Card centered>
                <Image src={d.image} wrapped ui={false} />
                <Card.Content>
                  <Card.Header>{d.header}</Card.Header>
                </Card.Content>
              </Card>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default HomepageLayout;
