import * as React from 'react';
import { Card, Divider, Icon, Segment, Header, Progress, Container, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const Store: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Segment className={styles.root} textAlign="center" vertical>
      <Header as="h2">
        <Header.Content>{t('store.title')}</Header.Content>
      </Header>
      <Progress percent={72} indicating size="medium" />
      <Container text textAlign="justified" className={styles.list}>
        <p>{t('store.description')}</p>
        <p>{t('store.description-2')}</p>
        <List as="ul">
          <List.Item as="li">{t('store.list-1')}</List.Item>
          <List.Item as="li">{t('store.list-2')}</List.Item>
          <List.Item as="li">{t('store.list-3')}</List.Item>
          <List.Item as="li">{t('store.list-4')}</List.Item>
        </List>
      </Container>
      <Divider horizontal>
        <Header as="h4">
          <Icon name="tag" />
          {t('store.divider-text')}
        </Header>
      </Divider>
      <Card.Group itemsPerRow={4}>
        <Card color="red" image="/images/photo_2020-01-08 13.13.58.jpeg" />
        <Card color="orange" image="/images/photo_2020-01-08 13.13.50.jpeg" />
        <Card color="yellow" image="/images/photo_2020-01-08 13.14.01.jpeg" />
        <Card color="yellow" image="/images/photo_2020-01-08 13.14.08.jpeg" />
      </Card.Group>
    </Segment>
  );
};

export default Store;
