import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as React from 'react';
import App from '../App';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Brands from './Brands';
import Store from './Store';

const NotFound = () => <div>NotFound</div>;

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={`/(about|about|contact|brands|store)?`}
          render={props => (
            <App routerProps={props}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/about" component={About} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/brands" component={Brands} />
                <Route exact path="/store" component={Store} />
                <Route component={NotFound} />
              </Switch>
            </App>
          )}
        />
      </Switch>
    </BrowserRouter>
  );
}
