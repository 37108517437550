import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DesktopContainer from './components/DesktopContaner';
import MobileContainer from './components/MobileContainer';
import { Loader } from 'semantic-ui-react';

interface ResponsiveContainer {
  children: React.ReactNode;
  routerProps: RouteComponentProps;
}

const App: React.FC<ResponsiveContainer> = ({ routerProps, children }) => {
  return (
    <React.Suspense fallback={<Loader />}>
      <DesktopContainer routerProps={routerProps}>{children}</DesktopContainer>
      <MobileContainer routerProps={routerProps}>{children}</MobileContainer>
    </React.Suspense>
  );
};

export default App;
